<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID " v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <el-row type="flex" justify="start">
        <el-button @click.stop.prevent="handleCreatePlace" class="margin-left-10" size="mini" type="success"
          icon="el-icon-plus">Agregar Lugar</el-button>
    </el-row>
    <!---- Tabla de Guías ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="ID" fixed width="100" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{ scope.row._id !== undefined ? scope.row._id : 'No disponible' }}</div>
            <span>{{ scope.row._id !== undefined ? scope.row._id.toString().substr(-8) : 'N/D' }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Imagen" width="100" align="center">
        <template slot-scope="scope">
              <div class="guide-image">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="`${pathServer + scope.row.image}`"
                  fit="scale-down"
                  :description="scope.row.description"
                  :preview-src-list="[pathServer + scope.row.image]">
                </el-image>
              </div>
        </template>
      </el-table-column>
      <el-table-column label="Icono" width="100" align="center">
        <template slot-scope="scope">
              <div class="guide-icon">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="`${pathServer + scope.row.icon}`"
                  fit="scale-down"
                  :description="scope.row.description"
                  :preview-src-list="[pathServer + scope.row.icon]">
                </el-image>
              </div>
        </template>
      </el-table-column>
      <el-table-column label="Título" align="center" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.title !== undefined ? scope.row.title : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre de Clave" align="center" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.keyName !== undefined ? scope.row.keyName : 'N/D' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Latitud" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.latitude !== undefined ? scope.row.latitude : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Longitud" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.longitude !==undefined ? scope.row.longitude : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Ubicación" width="140" align="center">
        <template slot-scope="scope">
          <el-link :href="scope.row.linkMaps" type="primary" target="_blank">Ver en Mapa</el-link>
        </template>
      </el-table-column>
      <el-table-column label="Opciones" width="180" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEditPlace(scope.row._id)">Editar</el-button>
          <el-button size="mini" type="danger" plain @click="handleDeletePlace(scope.$index, scope.row)">Eliminar</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { search, getPage, deletePlace } from '@/api/interestingPlaces.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'InterestingPlaces',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: [
      ]
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    // ---- Data guides ------
    handleCreatePlace () {
      this.$router.push('interestingPlaces/create')
    },
    handleDownloadDocument (file) {
    },
    handleEditPlace (id) {
      console.log('scope')
      this.$router.push(`interestingPlaces/edit/${id}`)
    },
    handleDeletePlace (index, row) {
      this.$confirm('Se borrará permanentemente. ¿Desea continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await deletePlace(row._id)
            .then(response => {
              this.$message({
                showClose: true,
                message: '¡Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operacion cancelada'
          })
        })
        .finally(() => (this.loading = false))
    },
    // --- data search ----
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.handleSearchFilter()
    }
  }
}
</script>

<style lang="scss"></style>
